<template>
  <span></span>
</template>

<script>
export default {
  name: "App",

  mounted() {
    localStorage.setItem("_", this.$route.params.code);
    localStorage.setItem("_p", "");
    this.$router.push("/");
  },
};
</script>
